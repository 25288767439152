<!-- src/App.vue -->
<template>
  <div id="app">
    <header>
      <h1>玩泥沙</h1>
    </header>
    <main>
      <HomePage />
    </main>
    <section class="life-moments">
      <h2>生活瞬间</h2>
      <div class="moment-container">
        <div class="moment-bubble">
          <img src="@/assets/lobacheva-ina-BAVhdgI9HiE.jpg" alt="悠闲时光">
          <h3>慢生活的艺术</h3>
          <p>今天,我们决定放慢脚步,享受生活的每一刻。小蛋糕在沙地上专注地堆砌他的城堡,丝毫不在意时间的流逝。看着他认真的样子,我意识到生活中最美好的时刻往往就在这些简单的日常里。</p>
        </div>
        <div class="moment-bubble">
          <img src="@/assets/gareth-harrison-fAjgtBJzp9E.jpg" alt="清晨">
          <h3>童年的快乐时光</h3>
          <p>周末带小蛋糕去了城市公园。看着他在绿地上奔跑、追逐蝴蝶的欢乐模样,仿佛看到了自己童年的影子。这份纯真和无忧无虑,是成长过程中最珍贵的礼物。我们一起野餐,分享笑声,度过了一个难忘的下午。</p>
        </div>
        <div class="moment-bubble">
          <img src="@/assets/child-play-with-sand.jpg" alt="玩沙记录">
          <h3>玩沙的乐趣</h3>
          <p>今天,小蛋糕在沙地上度过了一个愉快的下午。他用小铲子和桶创造了各种形状,堆砌了一座小城堡。看着他专注地用手指在沙子上画画,我感受到了孩子纯真的创造力。这简单的玩沙活动,不仅锻炼了他的手部精细动作,也激发了他的想象力。</p>
        </div>
      </div>
    </section>
    <footer>
      <p>&copy; 2024 玩泥沙. 保留所有权利。</p>
      <div style="display: flex; align-items: center;">
        <div style="display: flex; justify-content: center; align-items: center; width: 100%;">
          <span><img src="@/assets/police-logo01.dd7ff50e.png" alt="Police Logo" style="height: 1.5em; width: auto; vertical-align: middle;"></span>
          <span>&nbsp;</span>
          <a class="beian-link" rel="license" href="http://www.beian.gov.cn/portal/registerSystemInfo" target="_blank" title="Police">
            <span class="badge-subject">NIS</span>
            <span class="badge-value" style="background-color: #2983bb;">粤公网安备44011202002855号</span>
          </a>
          <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
          <a class="beian-link" rel="license" href="https://beian.miit.gov.cn/" target="_blank" title="ICP">
            <span class="badge-subject">ICP</span>
            <span class="badge-value" style="background-color: #fc8c23;">粤ICP备2021125857号-2</span>
          </a>          
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import HomePage from './views/NavHomePage.vue';

export default {
  name: 'App',
  components: {
    HomePage
  }
};
</script>

<style>
:root {
  --primary-color: #f8b195;
  --secondary-color: #f67280;
  --accent-color: #c06c84;
  --background-color: #ffefd5;
  --text-color: #34495e;
}

body {
  margin: 0;
  padding: 0;
  background-color: var(--background-color);
}

#app {
  font-family: 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  color: var(--text-color);
  line-height: 1.6;
}

header {
  background-color: var(--primary-color);
  color: white;
  padding: 20px;
  text-align: center;
}

h1 {
  margin: 0;
  font-size: 2.5em;
}

nav {
  background-color: var(--secondary-color);
  padding: 10px;
}

nav ul {
  list-style-type: none;
  padding: 0;
  display: flex;
  justify-content: center;
}

nav ul li {
  margin: 0 15px;
}

nav ul li a {
  color: white;
  text-decoration: none;
  font-weight: bold;
}

main {
  max-width: 1200px;
  margin: 20px auto;
  padding: 0 20px;
}

.life-moments {
  background-color: var(--background-color);
  padding: 20px;
  margin-top: 20px;
}

.life-moments h2 {
  text-align: center;
  color: var(--accent-color);
  font-size: 2em;
  margin-bottom: 30px;
}

.moment-container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.moment-bubble {
  background-color: white;
  border-radius: 20px;
  padding: 20px;
  margin: 10px;
  width: calc(33% - 20px);
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  transition: transform 0.3s ease;
}

.moment-bubble:hover {
  transform: translateY(-5px);
}

.moment-bubble img {
  width: 100%;
  border-radius: 10px;
  margin-bottom: 15px;
}

.moment-bubble h3 {
  color: var(--secondary-color);
  margin-bottom: 10px;
}

.moment-bubble p {
  font-size: 0.9em;
  line-height: 1.6;
}

footer {
  background-color: var(--accent-color);
  color: white;
  text-align: center;
  padding: 10px;
  margin-top: 20px;
}

.beian-link {
  color: #fff;
  font-size: 10px;
  text-decoration: none;
  display: inline-block;
  margin-top: 5px;
}

.badge-subject {
  background-color: #555;
  padding: 2px 4px;
  border-radius: 3px 0 0 3px;
}

.badge-value {
  background-color: #d14;
  padding: 2px 4px;
  border-radius: 0 3px 3px 0;
}

@media (max-width: 768px) {
  .moment-bubble {
    width: calc(50% - 20px);
  }
}

@media (max-width: 480px) {
  .moment-bubble {
    width: 100%;
  }
}
</style>

