<!-- src/views/HomePage.vue -->
<template>
  <div class="home">
    <div class="posts-container">
      <BlogPost
        v-for="(post, index) in posts"
        :key="index"
        :title="post.title"
        :coverImage="post.coverImage"
      >
        <p>{{ post.content }}</p>
      </BlogPost>
    </div>
    
    <div class="quote-section">
      <p class="quote">"童年是人生中最美好的时光,它如同一朵花,绽放得太快,凋谢得太早。我们要珍惜并记录下每一个瞬间,因为这些回忆将成为未来最珍贵的宝藏。"</p>
      <p class="author">— 泰戈尔</p>
      <p class="description">童年是一段短暂而珍贵的时光。在这个快节奏的世界里,我们更应该放慢脚步,细细品味孩子们成长的每一个瞬间。通过记录这些点滴过程,我们不仅为孩子们留下了美好的回忆,也为自己保存了人生中最纯真、最温暖的片段。让我们一起用心感受,用笔记录,让每一个童年的瞬间都成为永恒。</p>
    </div>
  </div>
</template>

<script>
import BlogPost from '../components/BlogPost.vue';

export default {
  name: 'NavHomePage',
  components: {
    BlogPost
  },
  data() {
    return {
      posts: [
        {
          title: '生活中的一天',
          coverImage: require('@/assets/xavier-mouton-2D5sGnLDfYk.jpg'),
          content: '探索日常生活中的美好。'
        },
        {
          title: '慢生活的艺术',
          coverImage: require('@/assets/saulius-sutkus-OINghjNA1rg.jpg'),
          content: '拥抱更加专注和放松的生活节奏。'
        },
        {
          title: '童年的快乐时光',
          coverImage: require('@/assets/children-8082256_1280.webp'),
          content: '记录孩子成长过程中的欢乐瞬间。'
        }
      ]
    };
  }
};
</script>

<style scoped>
.home {
  padding: 20px;
}

.posts-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
}

.posts-container > * {
  flex: 1 1 calc(33.333% - 20px);
  min-width: 250px;
  max-width: 300px;
  background-color: white;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
}

/* 新增样式 */
.posts-container :deep(.blog-post-image) {
  width: 75%;
  margin: 0 auto;
  display: block;
}

.home p {
  font-size: 16px;
  line-height: 1.6;
  color: var(--text-color);
  padding: 0 15px 15px;
}

.quote-section {
  background-color: white;
  border-radius: 20px;
  padding: 30px;
  margin-top: 40px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  text-align: center;
}

.quote {
  font-size: 1.2em;
  font-style: italic;
  color: var(--accent-color);
  margin-bottom: 10px;
}

.author {
  font-weight: bold;
  color: var(--secondary-color);
  margin-bottom: 20px;
}

.description {
  font-size: 1em;
  line-height: 1.8;
  color: var(--text-color);
}

@media (max-width: 768px) {
  .posts-container {
    flex-direction: column;
    align-items: center;
  }
  
  .posts-container > * {
    flex: 1 1 100%;
    max-width: 100%;
  }
}
</style>

