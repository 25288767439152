import { createApp } from 'vue';
import App from './App.vue';
import Home from './views/NavHomePage.vue';
import { createRouter, createWebHistory } from 'vue-router';

const routes = [
  { path: '/', component: Home }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

document.title = '玩泥沙';

createApp(App).use(router).mount('#app');

