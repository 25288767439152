<!-- src/components/BlogPost.vue -->
<template>
  <div class="blog-post">
    <h2>{{ title }}</h2>
    <img :src="coverImage" alt="Cover Image" class="cover-image" />
    <div class="content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    },
    coverImage: {
      type: String,
      required: true
    }
  }
};
</script>

<style scoped>
.blog-post {
  border: 1px solid #e1e1e1;
  padding: 20px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.blog-post h2 {
  font-size: 24px;
  color: #2c3e50;
  margin-bottom: 15px;
}

.blog-post .cover-image {
  width: 100%;
  height: auto;
  border-radius: 5px;
  margin-bottom: 15px;
}

.blog-post .content {
  font-size: 16px;
  color: #34495e;
}
</style>

